import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'data-table-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.css'],
})
export class ColumnFilterComponent implements OnInit {
  @Input()
  data: any;

  @Input()
  clrPosition = 'bottom-right';

  @Output()
  onColumnFilterChange = new EventEmitter();

  select: any = 'eq';

  date: any;
  toDate: any;

  value: any;
  toValue: any;

  optionValues = [];

  ngOnInit(): void {
    if ((this.data.type === 'select' || this.data.customType === 'select' || this.data.type === 'assignedRole') && this.data.options) {
      this.data.options.forEach((opt) => {
        this.optionValues.push({ name: opt, selected: false });
      });
    }
  }

  updateFiler() {
    let columnFilter = null;
    if ((this.data.type === 'date' || this.data.type == 'timePeriod') && this.date && this.date.length > 0) {
      if (this.data.type == 'timePeriod') this.select = 'Is';
      if (this.select === 'between' && this.toDate && this.toDate.length > 0) {
        columnFilter = {
          title: this.data.name,
          field: this.data.field,
          type: this.data.type,
          filter: this.select,
          values: [this.date, this.toDate],
        };
        /*    this.onColumnFilterChange.emit();*/
      } else {
        columnFilter = {
          title: this.data.name,
          field: this.data.field,
          type: this.data.type,
          filter: this.select,
          value: this.date,
        };
      }
    } else if (this.data.type === 'number' && this.value && this.value.length > 0) {
      if (this.select === 'between' && this.toValue && this.toValue.length > 0) {
        columnFilter = {
          title: this.data.name,
          field: this.data.field,
          type: this.data.type,
          filter: this.select,
          values: [this.value, this.toValue],
        };
      } else {
        columnFilter = {
          title: this.data.name,
          field: this.data.field,
          type: this.data.type,
          filter: this.select,
          value: this.value,
          toValue: this.toValue,
        };
      }
    } else if ((this.data.type === 'text' || this.data.customType === 'text') && this.value && this.value.length > 0) {
      columnFilter = {
        title: this.data.name,
        field: this.data.field,
        type: this.data.type,
        filter: this.select,
        value: this.value,
        model: this.data.model || ''
      };
    } else if (this.data.type === 'select' || this.data.customType === 'select') {
      if (this.optionValues.length > 0) {
        const selected = [];
        for (let i = 0; i < this.optionValues.length; i++) {
          if (this.optionValues[i].selected) {
            selected.push(this.optionValues[i].name);
          }
        }
        if (selected && selected.length > 0) {
          columnFilter = {
            title: this.data.name,
            field: this.data.field,
            type: this.data.type,
            customType: this.data.customType,
            filter: 'in',
            values: selected,
          };
        }
      }
    } else if (this.data.type === 'assignedRole') {
      if (this.optionValues.length > 0 && this.value) {
        const selected = [];
        for (let i = 0; i < this.optionValues.length; i++) {
          if (this.optionValues[i].selected) {
            selected.push(this.optionValues[i].name);
          }
        }
        if (selected && selected.length > 0) {
          columnFilter = {
            title: this.data.name,
            field: this.data.field,
            type: this.data.type,
            customType: this.data.customType,
            filter: '',
            values: { selected, value: this.value },
          };

        }
      }
    }
    if (columnFilter) {
      if (this.data.isChild) {
        columnFilter.isChild = this.data.isChild;
        columnFilter.customType = this.data.customType;
        columnFilter.model = this.data.model;
      }
      if (this.data.fields && this.data.fields.length) {
        columnFilter.fields = this.data.fields;
      }
      if (this.data.childName) {
        columnFilter.childName = this.data.childName;
      }
      this.onColumnFilterChange.emit(columnFilter);
    }

    this.select = 'eq';
    this.date = '';
    this.toDate = '';
    this.value = '';
    this.toValue = '';
    this.optionValues.forEach((op) => (op.selected = false));
  }
}
