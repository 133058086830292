import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, lastValueFrom } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from '../../customer/services/customer.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnChanges {

  @Input() selectedCompany: any = null
  countryArray: any[] = [];
  stateforClient: any[] = [];
  cityforClient: any[] = [];
  stateByCountry: any[];
  currentCountryCode: string;
  cityByState: any[];
  currentStateCode: string;
  companyForm: FormGroup;
  editPartner = false;
  constructor(private router: Router, private cdr: ChangeDetectorRef, private formBuilder: FormBuilder, private customerService: CustomerService, private commonService: CommonService) {

    this.editPartner = this.router.url.includes("settings/edit")
  }

  async ngOnChanges() {
    let res = await lastValueFrom(this.commonService.getCountryList())
    if (res?.data?.getCountryList) {
      this.countryArray = res.data.getCountryList
    }
    this.loadCompanyForm(this.selectedCompany);
    await this.stateUpdate(this.companyForm.value.country);
    await this.cityUpdate(this.companyForm.value.state);
    this.search = this.companyForm.value.companyName
    this.onChangeInput();
  }

  ngOnInit() {

  }

  async stateUpdate(countryName) {
    let countryObj = this.countryArray.find(country => country.name === countryName)
    this.stateByCountry = [];
    this.currentCountryCode = countryObj?.isoCode;
    let res = await lastValueFrom(this.commonService.getStateListByCountry(this.currentCountryCode))
    if (res?.data?.getStateListByCountry) {
      this.stateByCountry = res.data.getStateListByCountry
    }
    this.cityByState = []
    this.stateforClient = this.stateByCountry;
    this.onChangeInput();
  }


  async cityUpdate(stateName) {
    let stateObj = this.stateByCountry.find(state => state.name === stateName)
    this.cityByState = [];
    this.currentStateCode = stateObj?.isoCode
    let res = await lastValueFrom(this.commonService.getCityListByState(this.currentCountryCode, this.currentStateCode))
    if (res?.data?.getCityListByState) {
      this.cityByState = res.data.getCityListByState
    }
    this.cityforClient = this.cityByState;
    this.onChangeInput();
  }


  async onSave(type) {
    this.companyForm.get('companyName').setValue(this.search);
    if (this.companyForm.valid) {
      let res = await lastValueFrom(this.customerService.addOrUpdateCompany(this.companyForm.value, type))
      if (res?.data?.addOrUpdateCompany) {
        return res.data.addOrUpdateCompany;
      }
      else {
        return false;
      }
    }
  }
  resetCompanyForm() {
    this.companyForm.reset();
    this.search = "";
    this.onChangeInput();
  }

  // ===================================
  @Input()
  company: {
    companyName: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zipCode: number;
  };

  @Input()
  companyList: any[];

  @Output()
  companyUpdate = new EventEmitter<Object>();
  @Output()
  companyFormValidity = new EventEmitter<Boolean>();



  @Output()
  dropDownTouchedEmitter = new EventEmitter<Object>();

  @ViewChild('input', { static: false })
  searchText;

  @ViewChild('myInput')
  searchTextInput: ElementRef

  search: any;

  filteredList: any[];

  showlist: boolean = false;
  showData: boolean = false;

  // Get element referance
  @ViewChild('tgArea') tgArea: ElementRef;


  ngAfterViewInit() {


    if (this.companyList) {
      this.companyList.sort(function (st1, st2) {
        if (st1.name > st2.name) return 1;
        if (st1.name < st2.name) return -1;
        return 0;
      });
      this.filteredList = this.companyList;
      this.cdr.detectChanges();
    }
    this.searchText.valueChanges
      .pipe(debounceTime(200))
      .subscribe((model) => this.filterCompanyList(model));
    this.onChangeInput();
  }

  // Defind listener to check clickable area
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if (event.target == this.tgArea.nativeElement) {
      this.showlist = true;
    } else {
      this.showlist = false;
    }
    this.onChangeInput();
  }

  // Function to open list and prevent other events
  showCompanyListWithEvent(e) {
    const previousValue = this.showlist;
    this.showlist = !this.showlist;
    this.handleDropDownTouch(previousValue);
    e.preventDefault();
    e.stopPropagation();
    this.onChangeInput();
  }

  // Basic function to toggel list
  showCompanyList() {
    const previousValue = this.showlist;
    this.showlist = !this.showlist;
    this.handleDropDownTouch(previousValue);
    this.onChangeInput();
  }



  async handleSelectedCompany(selectedCompany) {
    this.company = selectedCompany;
    this.search = selectedCompany.companyName
    this.loadCompanyForm(selectedCompany);
    // if(this.companyForm.controls['country'].value.toLowerCase()=="us" || this.companyForm.controls['country'].value.toLowerCase() == "usa"){
    //   this.companyForm.controls['country'].setValue("United States");
    // }
    await this.stateUpdate(this.companyForm.controls['country'].value);
    await this.cityUpdate(this.companyForm.controls['state'].value);
    this.companyUpdate.emit({ value: true, data: this.company });
    this.showCompanyList();
    this.showlist = false;
    this.onChangeInput();
  }
  loadCompanyForm(selectedCompany?: any) {
    selectedCompany = this.stateCodeChange(selectedCompany);
    this.companyForm = this.formBuilder.group({
      id: [selectedCompany?.id || null],
      address: [selectedCompany?.address || "", [Validators.required]],
      companyName: [selectedCompany?.companyName || "", [Validators.required]],
      city: [selectedCompany?.city || "", [Validators.required]],
      state: [selectedCompany?.state || "", [Validators.required]],
      country: [selectedCompany?.country || "", [Validators.required]],
      zipCode: [selectedCompany?.zipCode || "", [Validators.required]],
      website: [selectedCompany?.website || ""],
      location: [selectedCompany?.location || ""],
      clientIdNo: [selectedCompany?.clientIdNo || ""],
      taxIdNo: [selectedCompany?.taxIdNo || ""],
      dunsNo: [selectedCompany?.dunsNo || null],
      creditRating: [selectedCompany?.creditRating || null],
    })

    this.search = this.companyForm.value.companyName
  }



  filterCompanyList(text) {
    if (text) {
      const filteredListX = this.companyList.filter(us => us.companyName?.toLowerCase().includes(text.toLowerCase()));
      this.filteredList = filteredListX;
    } else {
      this.filteredList = this.companyList;
    }
    this.onChangeInput();
  }

  handleDropDownTouch(showlistValue) {
    if (this.dropDownClosed(showlistValue))
      this.dropDownTouchedEmitter.emit({ message: "Dropdown Touched" });
    this.onChangeInput();
  }

  dropDownClosed(showlistValue) {
    this.onChangeInput();
    return showlistValue && !this.showlist;

  }

  onChangeInput() {
    this.companyForm.get('companyName').setValue(this.search);
    if (this.companyForm.valid) {
      this.companyFormValidity.emit(true);
    } else {
      this.companyFormValidity.emit(false);

    }
  }

  stateCodeChange(selectedCompany){
    if(selectedCompany?.country ==="USA" || selectedCompany?.country ==="US"){
      selectedCompany.country = "United States";
    }
    if(selectedCompany?.state === "AL")  selectedCompany.state = "Alabama";
    if(selectedCompany?.state === "AK")  selectedCompany.state = "Alaska";
    if(selectedCompany?.state === "AZ")  selectedCompany.state = "Arizona";
    if(selectedCompany?.state === "AR")  selectedCompany.state = "Arkansas";
    if(selectedCompany?.state === "CA")  selectedCompany.state = "California";
    if(selectedCompany?.state === "CO")  selectedCompany.state = "Colorado";
    if(selectedCompany?.state === "CT")  selectedCompany.state = "Connecticut";
    if(selectedCompany?.state === "DE")  selectedCompany.state = "Delaware";
    if(selectedCompany?.state === "DC")  selectedCompany.state = "District of Columbia";
    if(selectedCompany?.state === "FL")  selectedCompany.state = "Florida";
    if(selectedCompany?.state === "GA")  selectedCompany.state = "Georgia";
    if(selectedCompany?.state === "HI")  selectedCompany.state = "Hawaii";
    if(selectedCompany?.state === "ID")  selectedCompany.state = "Idaho";
    if(selectedCompany?.state === "IL")  selectedCompany.state = "Illinois";
    if(selectedCompany?.state === "IN")  selectedCompany.state = "Indiana";
    if(selectedCompany?.state === "IA")  selectedCompany.state = "Iowa";
    if(selectedCompany?.state === "KS")  selectedCompany.state = "Kansas";
    if(selectedCompany?.state === "KY")  selectedCompany.state = "Kentucky";
    if(selectedCompany?.state === "LA")  selectedCompany.state = "Louisiana";
    if(selectedCompany?.state === "ME")  selectedCompany.state = "Maine";
    if(selectedCompany?.state === "MD")  selectedCompany.state = "Maryland";
    if(selectedCompany?.state === "MA")  selectedCompany.state = "Massachusetts";
    if(selectedCompany?.state === "MI")  selectedCompany.state = "Michigan";
    if(selectedCompany?.state === "MN")  selectedCompany.state = "Minnesota";
    if(selectedCompany?.state === "MS")  selectedCompany.state = "Mississippi";
    if(selectedCompany?.state === "MO")  selectedCompany.state = "Missouri";
    if(selectedCompany?.state === "MT")  selectedCompany.state = "Montana";
    if(selectedCompany?.state === "NE")  selectedCompany.state = "Nebraska";
    if(selectedCompany?.state === "NV")  selectedCompany.state = "Nevada";
    if(selectedCompany?.state === "NH")  selectedCompany.state = "New Hampshire";
    if(selectedCompany?.state === "NJ")  selectedCompany.state = "New Jersey";
    if(selectedCompany?.state === "NM")  selectedCompany.state = "New Mexico";
    if(selectedCompany?.state === "NY")  selectedCompany.state = "New York";
    if(selectedCompany?.state === "NC")  selectedCompany.state = "North Carolina";
    if(selectedCompany?.state === "ND")  selectedCompany.state = "North Dakota";
    if(selectedCompany?.state === "OH")  selectedCompany.state = "Ohio";
    if(selectedCompany?.state === "OK")  selectedCompany.state = "Oklahoma";
    if(selectedCompany?.state === "OR")  selectedCompany.state = "Oregon";
    if(selectedCompany?.state === "PA")  selectedCompany.state = "Pennsylvania";
    if(selectedCompany?.state === "RI")  selectedCompany.state = "Rhode Island";
    if(selectedCompany?.state === "SC")  selectedCompany.state = "South Carolina";
    if(selectedCompany?.state === "SD")  selectedCompany.state = "South Dakota";
    if(selectedCompany?.state === "TN")  selectedCompany.state = "Tennessee";
    if(selectedCompany?.state === "TX")  selectedCompany.state = "Texas";
    if(selectedCompany?.state === "UT")  selectedCompany.state = "Utah";
    if(selectedCompany?.state === "VT")  selectedCompany.state = "Vermont";
    if(selectedCompany?.state === "VA")  selectedCompany.state = "Virginia";
    if(selectedCompany?.state === "WA")  selectedCompany.state = "Washington";
    if(selectedCompany?.state === "WV")  selectedCompany.state = "West Virginia";
    if(selectedCompany?.state === "WI")  selectedCompany.state = "Wisconsin";
    if(selectedCompany?.state === "WY")  selectedCompany.state = "Wyoming";
    
    return selectedCompany;
  }
}

