<ng-container *ngIf="user">
  <div class="user-profiler row">
    <div class="w-auto p-0"> <img
      [src]="user.photoLink || 'assets/images/user.svg'"
      class="profiller-image" />
    </div>
   
    <div class="profiler-content w-auto p-0">
      <h5>
        <b>{{ user.name || user.email }}</b>
        <ng-container *ngIf="user.badge"
          ><span class="badge">{{ user.badge }}</span></ng-container
        >
      </h5>
      <p class="sml-txt">{{ user.title }}</p>
    </div>
  </div>
</ng-container>
