<clr-dropdown class="pull-right" [clrCloseMenuOnItemClick]="false" *ngIf="data.name">
  <!-- trigger element -->
  <a class="drop-trigger" clrDropdownTrigger style="margin-right: -9px;">
    <i class="fa fa-filter" *ngIf="data.type != 'assignedRole'"></i>
    <span class="btn btn-primary" *ngIf="data.type == 'assignedRole' || data.type == 'timePeriod'">Additional Filter</span>
  </a>
  <clr-dropdown-menu style="margin: 15px 0px; padding: 0px" [clrPosition]="clrPosition">
    <div style="padding: 10px 24px" class="mb-2">
      <span class="heading-small-c">{{ data.name }}</span>
    </div>
    <div class="filter-outer-wrapper">
      <div clrDropdownItem *ngIf="data.type == 'number' || data.customType == 'number'">
        <select [(ngModel)]="select">
          <option value="eq" selected>Is</option>
          <option value="gte">Greater</option>
          <option value="lte">Smaller</option>
          <option value="between">Between</option>
        </select>
      </div>
      <div clrDropdownItem *ngIf="data.type == 'date'">
        <select [(ngModel)]="select">
          <option value="eq" selected>Is</option>
          <option value="lte">Before</option>
          <option value="gte">After</option>
          <option value="between">Between</option>
        </select>
      </div>
      <div clrDropdownItem *ngIf="data.type == 'text' || data.customType == 'text'">
        <select [(ngModel)]="select">
          <option value="eq" selected>Is</option>
          <option value="substring">Contains</option>
          <option value="startsWith">Starts with</option>
          <option value="endsWith">Ends with</option>
        </select>
      </div>
      <div class="date-picker-filter" clrDropdownItem *ngIf="data.type == 'date' || data.type == 'timePeriod'">
        <input clrDate placeholder="Date" [(ngModel)]="date" />
      </div>
      <div class="date-picker-filter" clrDropdownItem *ngIf="data.type == 'date' && select == 'between'">
        <input clrDate placeholder="To Date" [(ngModel)]="toDate" />
      </div>
      <div clrDropdownItem *ngIf="data.type == 'number' || data.customType == 'number'">
        <input placeholder="Value" [(ngModel)]="value" />
      </div>
      <div clrDropdownItem *ngIf="(data.type == 'number' || data.customType == 'number') && select == 'between'">
        <input placeholder="To value" [(ngModel)]="toValue" />
      </div>
      <div clrDropdownItem *ngIf="data.type == 'text' || data.customType == 'text'">
        <input placeholder="Text" [(ngModel)]="value" />
      </div>
      <div clrDropdownItem *ngIf="data.type == 'select' || data.customType == 'select'">
        <clr-checkbox-container>
          <clr-checkbox-wrapper *ngFor="let opt of optionValues; index as ind">
            <input type="checkbox" clrCheckbox [(ngModel)]="opt.selected" />
            <label>{{ opt.name }}</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
      </div>
      <div clrDropdownItem *ngIf="data.type == 'assignedRole' || data.customType == 'assignedRole'">
        <div class="pl-10 pt-2">
          <input placeholder="Enter Employee Name" [(ngModel)]="value" />
        </div>
        <clr-checkbox-container>
          <clr-checkbox-wrapper *ngFor="let opt of optionValues; index as ind">
            <input type="checkbox" clrCheckbox [(ngModel)]="opt.selected" />
            <label>{{ opt.name }}</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
      </div>
      <!-- <div clrDropdownItem *ngIf="data.type == 'timePeriod' || data.customType == 'timePeriod'">
        <div class="pl-10 pt-2">
          <label>From: </label><br>
          <input placeholder="Enter Employee Name" type="date" [(ngModel)]="fromTimePeriod" />
        </div>
        <div class="pl-10 pt-2">
          <label>To: </label><br>
          <input placeholder="Enter Employee Name" type="date" [(ngModel)]="toTimePeriod" />
        </div>
      </div> -->
    </div>
    <div style="padding: 10px 24px" class="mt-2">
      <button class="btn btn-primary btn-sm" clrDropdownTrigger (click)="updateFiler()">Update filter</button>
    </div>
  </clr-dropdown-menu>
</clr-dropdown>
