<form [formGroup]="companyForm" *ngIf="companyForm">
  <div class="details-accordions clientform-accordian">
    <div class="row edit-mode">
      <div class="col-md-12 col-xl-6">
        <ng-container>
          <div class="row pt-2">
            <div class="col-md-8 company-position">
              <p class="sml-lable-heading">Company / Organization Name<span class="required">*</span></p>
              <h5 class="sml-extra-heading" style="position: relative">
                <input
                  #tgArea
                  type="text"
                  autocomplete="off"
                  placeholder="Start typing the name.."
                  class="w-100"
                  [(ngModel)]="search"
                  #input="ngModel"
                  id="search"
                  name="search"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangeInput()"
                />
                <span
                  *ngIf="!editPartner"
                  (click)="resetCompanyForm()"
                  style="position: absolute; top: 25%; padding-left: 10px"
                  class="reset-form-btn"
                  title="Reset Company Form"
                  ><i class="fa fa-times" aria-hidden="true"></i
                ></span>
              </h5>
              <div class="list-view-cnt company-list-view-cnt w-100s" style="top: 70px" [ngClass]="showlist ? 'show' : ''">
                <section class="ta-results list-group" *ngFor="let company of filteredList">
                  <button type="button" class="ta-item list-group-item" (click)="handleSelectedCompany(company)">
                    <div class="item-name">
                      <div class="profiler-content">
                        <h5>
                          <b>{{ company?.companyName }}</b>
                        </h5>
                        <p class="sml-txt">
                          {{ company.address }}
                          {{ company.city ? (company.city !== 'null' ? ', ' + company.city : '') : '' }}
                          {{ company.state ? (company.state !== 'null' ? ', ' + company.state : '') : '' }}
                          {{ company.country ? (company.country !== 'null' ? ', ' + company.country : '') : '' }}
                          {{ company.zipCode ? (company.zipCode !== 'null' ? ', ' + company.zipCode : '') : '' }}
                        </p>
                      </div>
                    </div>
                  </button>
                </section>
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-md-8">
              <p class="sml-lable-heading">Address<span class="required">*</span></p>
              <h5 class="sml-extra-heading">
                <clr-input-container>
                  <input (change)="onChangeInput()" clrInput type="text" class="w-100" formControlName="address" />
                  <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                </clr-input-container>
              </h5>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-md-4">
              <p class="sml-lable-heading">Country<span class="required">*</span></p>
              <h5 class="sml-extra-heading">
                <clr-input-container>
                  <select
                    clrInput
                    class="w-100 clr-select"
                    (change)="stateUpdate($event.target.value.split(': ')[1]); onChangeInput()"
                    formControlName="country"
                  >
                    <option value="null">--</option>
                    <option *ngFor="let country of countryArray" [ngValue]="country.name">
                      {{ country.name }}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                </clr-input-container>
              </h5>
            </div>
            <div class="col-md-4 m-p-12px">
              <p class="sml-lable-heading">State<span class="required">*</span></p>
              <h5 class="sml-extra-heading">
                <clr-input-container>
                  <select
                    class="w-100 clr-select"
                    clrInput
                    (change)="cityUpdate($event.target.value.split(': ')[1]); onChangeInput()"
                    formControlName="state"
                  >
                    <option value="null">--</option>
                    <option *ngFor="let state of stateforClient" [ngValue]="state.name">
                      {{ state.name }}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                </clr-input-container>
              </h5>
            </div>
            <div class="col-md-4">
              <p class="sml-lable-heading">City<span class="required">*</span></p>
              <h5 class="sml-extra-heading">
                <clr-input-container>
                  <select clrInput class="w-100 clr-select" formControlName="city" (change)="onChangeInput()">
                    <option value="null">--</option>
                    <option *ngFor="let city of cityforClient" [ngValue]="city.name">
                      {{ city.name }}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                </clr-input-container>
              </h5>
            </div>

            <div class="col-md-4 m-p-12px">
              <p class="sml-lable-heading">ZipCode<span class="required">*</span></p>
              <h5 class="sml-extra-heading">
                <clr-input-container>
                  <input type="text" clrInput class="w-100" (change)="onChangeInput()" formControlName="zipCode" />
                  <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                </clr-input-container>
              </h5>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="col-md-8 col-xl-6 m-p-12px pt-3">
        <table class="table table-vertical mt-0 client_information_table mobile-table-edit-view">
          <tbody>
            <tr>
              <th>Parent Company / Location</th>
              <td><input type="text" formControlName="location" /></td>
            </tr>
            <tr>
              <th>Website</th>
              <td><input type="text" formControlName="website" /></td>
            </tr>
            <tr>
              <th>Client ID No.</th>
              <td><input type="text" formControlName="clientIdNo" /></td>
            </tr>
            <tr>
              <th>Tax ID No.</th>
              <td><input type="text" formControlName="taxIdNo" /></td>
            </tr>
            <tr>
              <th>DUNS No.</th>
              <td><input type="text" formControlName="dunsNo" /></td>
            </tr>
            <tr>
              <th>Credit Rating</th>
              <td><input type="text" formControlName="creditRating" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
