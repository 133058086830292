import { Directive, HostListener, ElementRef, OnInit, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import { CurrencyPipe } from "../pipes/pipes";

@Directive({ selector: "[currency]" })
export class CurrencyDirective implements AfterViewInit {

  private el: HTMLInputElement;
  regexStr = '^[0-9]*$';

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.el.value = this.currencyPipe.transform(this.el.value);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.el.value = this.currencyPipe.parse(value, 2); // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = this.currencyPipe.transform(value);
  }

  @HostListener("change", ["$event.target.value"])
  onChange(value) {
    this.el.value = this.currencyPipe.transform(value);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    let ch = e.key;
    let allowedKeys = ['Backspace', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Delete', '-', '.'];
    let regEx = new RegExp(this.regexStr);
    if (regEx.test(ch) || (allowedKeys.indexOf(e.key) >= 0))
      return;
    else
      e.preventDefault();
  }
}

@Directive({ selector: "[percent]" })
export class PercentDirective implements AfterViewInit {

  private el: HTMLInputElement;
  regexStr = '^[0-9]*$';

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.el.value = this.parseDecimal(this.el.value);
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = this.parseDecimal(value);
  }

  @HostListener("change", ["$event.target.value"])
  onChange(value) {
    this.el.value = this.parseDecimal(value);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    let ch = String.fromCharCode(e.keyCode);
    let regEx = new RegExp(this.regexStr);
    if (regEx.test(ch) || ([8, 37, 38, 39, 40, 46, 189].indexOf(e.keyCode) >= 0))
      return;
    else
      e.preventDefault();
  }

  parseDecimal(val) {
    if (val == undefined || val == null || val == '') val = 0;
    var value = parseFloat(val);
    return value > 100 ? (100).toFixed(2) : value < -100 ? (-100).toFixed(2) : value.toFixed(2);
  }
}