import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostService } from './host.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private readonly hostService: HostService, private tenantService: TenantService) { }

  loadConfigurationSettings() {
    return this.loadUserDetails().then((data) => { 
      return true;
    });
  }

  async loadUserDetails() {
    return new Promise(async (resolve, reject) => {
      // Save default tenant if not already saved
      let currentTenant = this.hostService.getCurrentTenant();
      if (!currentTenant) {
        var userInfo = this.hostService.getUserInfo();
        var userTenants = this.hostService.getTenants();
        var defaultTenant = userTenants.find(ut => ut.id == userInfo.defaultTenantId);
        if (defaultTenant && defaultTenant.tenancyName != currentTenant) {
          this.hostService.setCurrentTenant(defaultTenant.tenancyName);
        }
      }
      await this.tenantService.loadUserRoles();
      return resolve(true);
    });
  }
}