import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../environments/environment';
import jwt_decode from 'jwt-decode';
import { KeycloakService } from './auth/keycloak.service';
import { EventService } from './event.service';
import { supportsScrollBehavior } from '@angular/cdk/platform';

import { Observable, throwError, from, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HostService } from './host.service';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from './alert.service';
@Injectable()
export class AuthService {
  // authStorage = new AuthStorage();

  bashUrl = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private authStorage: AuthStorage,
    private keycloakService: KeycloakService
  ) {
    this.bashUrl = `${environment.baseUrl}/api`;
  }

  isAuthorized() {
    const authData = this.authStorage.getAuthData();
    return !!authData;
  }

  loginWithGoogle(code: string) {
    return this.http.get<any>(`${this.bashUrl}/google/callback?code=${code}`);
  }

  logout() {
    // this.authStorage.removeAuthorizationHeader();
    // this.router.navigate(['/login']);
    this.keycloakService.redirectToLogout();
  }

  setLoginUserData(authResponse) {
    // this.authStorage.setAuthorizationHeader(authResponse);
  }

  setAuthorizationHeader(authResponse) {
    // this.authStorage.setAuthorizationHeader(authResponse);
  }
}

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthorized()) {
      return true;
    }
    return false;
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private broadcaster: EventService,
    private cookieService: CookieService,
    private keycloakService: KeycloakService,
    private hostService: HostService,
    private alertService: AlertService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.broadcaster.BroadcastEvent("LOADER_EVENT", "show");

    if (
      request.url.includes(`/api/host/authenticate`) ||
      request.url.includes(`/api/host/token/revoke`) ||
      request.url.includes(`/api/host/config/keycloak`)
    ) {
      return this.handleRequestWithoutToken(request, next);
    }

    let kcToken = this.cookieService.get('auth_token');
    //handling unauthorized access case with expiration time, when cookie is not getting deleted but expired.
    if (kcToken) {
      const decodedKCToken: any = jwt_decode(kcToken);
      const currTime = new Date().getTime() / 1000;
      if (currTime >= decodedKCToken.exp) {
        this.cookieService.delete('auth_token');
        kcToken = null;
      }
    }
    if (!kcToken) {
      return from(this.keycloakService.redirectToLogin()).pipe(
        switchMap((res) => {
          return this.handleRequestWithToken(request, next, this.cookieService.get('auth_token'))
        }),
        catchError((err) => {
          // handle for the error that can occur
          this.broadcaster.BroadcastEvent("LOADER_EVENT", "hide");
          const error = err.error.message || this.getParse(err);
          this.alertService.error(error);
          return throwError(error);
        })
      )
    }
    else {
      return this.handleRequestWithToken(request, next, kcToken);
    }
  }

  private handleRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string | null
  ): Observable<HttpEvent<any>> {
    let tenancyName = this.hostService.getCurrentTenant();
    request = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
        ...(request.url.includes("/api") && tenancyName) && { 'canyon-tenant-id': tenancyName },
      },
    });

    return next.handle(request)
      .pipe(map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.broadcaster.BroadcastEvent("LOADER_EVENT", "hide");
        }
        return event;
      }), catchError((err) => {
        if (request.url === "/api/user") {
          document.body.innerHTML = `<div class="error-alert">Unauthorized access</div>`
          this.keycloakService.redirectToLogout();
        }
        this.broadcaster.BroadcastEvent("LOADER_EVENT", "hide");
        const error = err.error.message || this.getParse(err);
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          // show alert that user is not authorize to access the service 
          this.alertService.error("Not authorized to access");
        }
        //managing graphql errors
        else if (err.status === 400) {
          if (err.error.errors[0].extensions.code === 'UNAUTHENTICATED') {
            this.alertService.error("Not authorized to access");
          }
        }
        else if (err.status === 409) {
          this.alertService.error(err.error.error.message);
        }
        return throwError(error);
      }));
  }

  private handleRequestWithoutToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.broadcaster.BroadcastEvent("LOADER_EVENT", "hide");
        }
        return event;
      }), catchError((err) => {
        this.broadcaster.BroadcastEvent("LOADER_EVENT", "hide");
        const error = err.error.message || this.getParse(err);
        if (err.status === 401) {
          // show alert that user is not authorize to access the service 
          this.alertService.error("Not authorized to access");
        }
        return throwError(error);
      }));
  }

  getParse(error) {
    try {
      return JSON.parse(error.error);
    } catch (err) {
      return error.statusText;
    }
  }
}

@Injectable()
export class AuthStorage {
  constructor(
    private cookieService: CookieService
  ) {

  }
  getAuthData(): any {

    // const authData = window.localStorage.getItem('auth_token');
    const authData = this.cookieService.get('auth_token')
    if (authData) {
      return jwt_decode(authData);
    } else {
      return null;
    }
  }

  getAuthorizationHeader() {
    // const token = window.localStorage.getItem('auth_token');
    const token = this.cookieService.get('auth_token')
    if (token) {
      return token;
    }
  }

  // setAuthorizationHeader(authResponse) {
  //   localStorage.setItem('auth_token', authResponse);
  // }

  // removeAuthorizationHeader() {
  //   localStorage.removeItem('auth_token');
  // }
}

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(private router: Router, private authStorage: AuthStorage, private keycloakService: KeycloakService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (this.keycloakService.introspect()) {
      //if true then the user will get logged in if not already
      const data = next.data.expectedRole;
      return this.verifyRoles(data);
    }
    return false;

    // if (
    //   !(
    //     KeycloakService.auth.loggedIn &&
    //     KeycloakService.auth.authz.authenticated
    //   )
    // ) {
    //   KeycloakService.login();
    //   return false;
    // }

    // this.router.navigate(['/login']);
    //redirect to login
    // this.keycloakService.redirectToLogin();

  }
  verifyRoles(data: any) {
    if (data && KeycloakService.hasRoles(data)) {
      return true;
    } else {
      return false;
    }
  }
}

