<div class="login-wrapper">
  <div class="login">
    <section class="title">
      <img
        src="assets/images/ArchstoneLogoUpdated.png"
        height="150px"
        width="100%"
      />
      <h5 class="mt-4">
        You are not authorized to access the application. Please reach out to
        administrator to get access.
      </h5>
    </section>
    <div class="login-group">
      <div class="py-3">
        <a class="login-with-google-btn" (click)="onLogin()">
          Login with google</a
        >
      </div>
    </div>
  </div>
</div>
