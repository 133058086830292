import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer} from '@angular/platform-browser';
const PADDING = "000000";
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 
@Pipe({ name: "CurrencyPipe" })
export class CurrencyPipe implements PipeTransform {

  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor() {
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = ".";
    this.THOUSANDS_SEPARATOR = ",";
  }

  transform(value: number | string, fractionSize: number = 2): string {
    let [ integer, fraction = "" ] = (value || "0").toString().replace(/[^\d.-]/g, '')
      .split(this.DECIMAL_SEPARATOR);

    fraction = fractionSize > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : this.DECIMAL_SEPARATOR + "00";

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
    return integer + fraction;
  }

  parse(value: string, fractionSize: number = 2): string {
    let [ integer, fraction = "" ] = (value || "").split(this.DECIMAL_SEPARATOR);
    integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, "g"), "");
    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : this.DECIMAL_SEPARATOR + "00";
    return integer + fraction;
  }

}


// @Pipe({ name: "PercentPipe" })
// export class PercentPipe implements PipeTransform {

//   private DECIMAL_SEPARATOR: string;

//   constructor() {
//     this.DECIMAL_SEPARATOR = ".";
//   }

//   transform(value: number | string, fractionSize: number = 2): string {
//     let [ integer, fraction = "" ] = (value || "").toString().replace(/[^\d.-]/g, '')
//       .split(this.DECIMAL_SEPARATOR);

//     fraction = fractionSize > 0
//       ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
//       : "";

//     integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
//     return integer + fraction;
//   }

//   parse(value: string, fractionSize: number = 2): string {
//     let [ integer, fraction = "" ] = (value || "").split(this.DECIMAL_SEPARATOR);
//     integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, "g"), "");
//     fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
//       ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
//       : "";

//     return integer + fraction;
//   }

// }