import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class TenantService {
    bashUrl = '';
    constructor(private http: HttpClient) {
        this.bashUrl = `${environment.baseUrl}/api`;
    }
    async loadUserRoles() {
        return new Promise(async (resolve, reject) => {
            // Get and save user info
            const userRoles = await lastValueFrom(this.http.get<any>(`${this.bashUrl}/user`));
            sessionStorage.setItem('user-tenant-roles', JSON.stringify(userRoles.success.data));
            resolve(true);
        });
    }
    hasRole(roleName) {
        let tenantUser = JSON.parse(sessionStorage.getItem('user-tenant-roles'));
        if (tenantUser && tenantUser.roles) {
            return tenantUser.roles.indexOf(roleName) >= 0;
        }
    }
}
