import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GraphqlService {
  graphUrl: string = '';

  constructor(private http: HttpClient) {
    this.graphUrl = `http://localhost:3009/graphql`;
  }

  query(query: any) {
    return this.http.post<any>(this.graphUrl, { query: query });
  }
}
