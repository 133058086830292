import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: 'user.profile.component.html',
})
export class UserProfileComponent implements OnInit {
  @Input()
  user: {
    photoLink: string;
    name: string;
    email: string;
    title: string;
    badge: string;
  };

  constructor() {}

  ngOnInit(): void {}

  getPhotoLink(photoLink) {
    if (!(photoLink.includes('http') || photoLink.includes('https'))) {
      photoLink = environment.baseUrl + '/api/images/' + photoLink;
    }
    return photoLink;
  }
}
