import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { EventService } from '../../services/event.service';
import { KeycloakService } from '../../services/auth/keycloak.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    private broadcaster: EventService,
  ) {
    // if (KeycloakService.isRegisterUser()) {
    //   this.router.navigate(['']);
    // }
  }

  ngOnInit() {
    // this.loading = false;
  }

  onLogin() {
    // KeycloakService.logout();
  }

  ngOnDestroy() {
    // this.authService.unsubscribe();
    // this.activatedRouteSubscription.unsubscribe();
  }
}
