<mat-form-field class="example-chip-list chip-set-list" appearance="fill">
  <!-- <mat-chip-list #chipList aria-label="option selection">
    <mat-chip *ngFor="let option of options" (removed)="remove(option)">
      {{ option }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      #optionInput
      [formControl]="optionCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list> -->

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
