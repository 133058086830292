import { Component, Input, Output, EventEmitter, ElementRef, Inject, AfterViewInit, ViewChild, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'data-table',
  templateUrl: './datatable.html',
  styleUrls: ['./datatable.css'],
})
export class DatatableComponent implements AfterViewInit {

  @Output()
  onFilterChange = new EventEmitter();

  @Output()
  onRemoveFilter = new EventEmitter();

  @Input()
  onCellRender: Function;

  @Output()
  onCellClick = new EventEmitter();

  @Input()
  columns: any;

  @Input()
  data: any;

  @Input()
  filters: any;

  @Input()
  class: any;

  @ViewChild('input', { static: false })
  searchText;

  search = '';

  renderCell(data) {
    return this.onCellRender(data);
  }

  ngAfterViewInit() {
    this.searchText.valueChanges.pipe(debounceTime(1000)).subscribe((model) => this.updateSearchField(model));
  }

  updateSearchField(value) {
    if (this.filters.searchText !== value) {
      this.filters.searchText = value;
      this.filterChanged();
    }
  }

  cellClick(column, item, event) {
    item.column = column;
    item.coreEvent = event;
    if (column.clickable) {
      this.onCellClick.emit(item);
    }
  }

  filterChanged() {
    this.onFilterChange.emit(this.filters);
  }

  sort(column, model = '', fields = [], data) {
    if (this.filters.sortBy === column || this.filters.sortBy === data.sortOrderName) {

      this.filters.sortOrder = this.filters.sortOrder === 0 ? 1 : 0;
    } else {
      if (data.sortOrderName) {
        this.filters.sortBy = data.sortOrderName;
      }
      else this.filters.sortBy = column;

      this.filters.sortOrder = 0;
    }
    this.filters.model = model;
    this.filters.fields = fields;
    this.filterChanged();
  }

  pageChanged(event) {
    this.filters.pageIndex = event.page;
    this.filterChanged();
  }

  onColumnFilterChange(event) {
    this.filters.columnFilters.push(event);
    this.filterChanged();
  }

  removeColumnFilter(i) {
    if (this.filters.columnFilters[i].additionalFilter && this.filters.columnFilters[i].filterTable == "directory") {
      this.onRemoveFilter.emit(this.filters.columnFilters[i])
    }
    this.filters.columnFilters.splice(i, 1);
    this.filterChanged();
  }

  formatAmount(value: any){
    value = value.toString();
    return value.replace(/[^\d.]/g, '');
  }
}
