import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { GraphqlService } from './graphql.service';
import { MatTabBody } from '@angular/material/tabs';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectService {


  bashUrl = '';
  bashEmployeeUrl = '';

  constructor(private http: HttpClient, private router: Router, private graphqlService: GraphqlService) {
    this.bashUrl = `${environment.baseUrl}/api/project`;
    this.bashEmployeeUrl = `${environment.baseUrl}/api/employee`;

  }


  getProjectByFilter(filterData: any) {
    return this.http.post<any>(`${this.bashUrl}/list`, filterData);
  }
  getProject(filter: any) {
    let url = `${this.bashUrl}?limit=`;
    if (filter.onlyName) {
      url = `${url}&onlyName=${filter.onlyName}`;
    }
    if (filter.projectType) {
      url = `${url}&projectType=${filter.projectType}`;
    }
    if (filter.id) {
      url = `${url}&&id=${filter.id}`;
    }
    return this.http.get<any>(url);
  }
  getProjectSpecificationsData(id, model) {
    return this.http.get<any>(`${this.bashUrl}/${id}/${model}`);
  }

  getProjectReport() {
    const url = `${this.bashUrl}/report`;
    return this.http.get<any>(url);
  }



  createProjectFolder(projectInfo) {
    let baseUrl = environment.baseUrl === "http://localhost:3009" ? 'http://localhost:3000' : environment.baseUrl;
    const url = `${baseUrl}/sync/project/folders`;
    return this.http.post<any>(url, projectInfo);
  }

  getEmployEmail() {
    const url = `${this.bashEmployeeUrl}?onlyEmail=true`;
    return this.http.get<any>(url);
  }

  newProject(data): Observable<any> {
    return this.http.post<any>(`${this.bashUrl}`, data);
  }

  getById(id) {
    const query = `{
      project(id: "${id}")
      {
        id name projectId startDate dueDate endDate projectType projectStatus projectPurpose sheetId folderId
        accountManager {
          id email title name photoLink
        }
        projectManager {
          id email title name photoLink
        }
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getStaffByProjectId(id) {
    const query = `{
      projectStaffs(projectId: "${id}")
      {
        id type isDefaultRoleStaff
        staff{
          id name email photoLink title employmentType
        }
        projectRole{
          id name
        }
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getDetailsByProjectId(id) {
    const query = `{
      project(id: "${id}")
      {
        id
        clientFileNumber
        companyAssignmentId
        properties{
          id property propertyName propertyType propertySubtype address city neighborhood state country county zipCode notes parcelId NumberOfProperties
          numberOfBuildings numberOfStories grossBuildingArea netRentableArea landArea zoning occupancy yearBuilt renovation 
        }
        complexities {
          id appraisalReportType propertyTypeDiffInLevel marketDiffInLevel inspectionDiffInLevel reportTypeDiffInLevel
          clientDiffInLevel totalInLevel propertyTypeDiffInWeight marketDiffInWeight inspectionDiffInWeight reportTypeDiffInWeight
          clientDiffInWeight totalInWeight propertyTypeDiff marketDiff inspectionDiff reportTypeDiff clientDiff
        }
        assignments {
          id appraisalReportType effectiveDate inspectionDate reportDate intendedUse intendedUser purpose appraisedAssets
          salesHistory valueDefinition propertyRightsAppraised scopeOfWork personalProperty intangibleAndBusiness otherInformation format reportFormatingType valueTypes
        }
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  // getFeeDistributionByProjectId(id) {
  //   const query = `{
  //     projectFeeDistributions(projectId: "${id}")
  //     {
  //       id allocatedFeePercentage allocatedFeeAmount paidAmount paymentAmount
  //       paymentId paymentStatus paymentDeliveryDate comments
  //       staff{
  //         id name email photoLink title
  //       }
  //     }
  //   }`;
  //   return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
  //     query: query,
  //   });
  // }

  getRoleProgressesByProjectId(id) {
    const query = `{
      projectRoleProgresses(projectId: "${id}")
      {
        id 
        rid 
        totalTargetTime
        actualTimeActual
        totalActualFee
        totalTargetTime
        actualTimeActual
        actualTimeTrainee
        actualTimeStaff
        overrunTrackingTimeHrs
        overrunTrackingPercent

        staff{
          name
        }
        projectRole{
          id
          name
        }
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getGeneralContractInformationByProjectId(id) {
    const query = `{
      projectContractInformation(id: "${id}"){
        id key value comment
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getBillingByProjectId(id) {
    const query = `{
      clientBilling(projectId: "${id}")
      {
        id milestones invoiceId invoiceDate amount paymentStatus paymentReceivingDate comments projectId
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getFeeAllocationByProjectId(id) {
    const query = `{
      projectCompanyFeeAllocations(projectId: "${id}")
      {
        splitPercent overheadFee referralFee inspectionFee appraisalFee reviewFee totalFee companyId id
        concludedAmountOfNetFee
        company{
          companyName
        }
        projectId
        projects {
          allocApproach
          projectFee{
            name
            type
            amount
          }
        }
        isPrimary
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getStaffFeeByProjectId(id) {
    const query = `{
      projectStaffPerformances(projectId: "${id}")
      {
        id
        actualFeePerHourPercentage
        actualTimeChargedHrs
        budgetFeePerHour
        feeAllocPercentage
        feesplitRatePercentage
        feesplitRateAmount
        no
        overheadRatePercentage
        overheadRateAmount
        profitRatePercentage
        profitRateAmount
        targetTimeMaximumHours
        targetTimeMinimumHours
        targetTimeTotalHours
        totalActualFee
        totalBudgetFee
        workCompletePercentage
        overrunTrackingTimeHrs
        overrunTrackingPercent        
        ratingsTime
        ratingsQuality
        ratingsFee
        ratingsOverallPerform
        remainingHours
        staff{
          name id
        }
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, { query: query });
  }

  getAssignmentByProjectId(id) {
    const query = `{
      project(id: "${id}")
      { id
        assignments {
          id appraisalReportType effectiveDate inspectionDate reportDate intendedUse intendedUser purpose appraisedAssets
          salesHistory valueDefinition propertyRightsAppraised scopeOfWork personalProperty intangibleAndBusiness otherInformation reportFormatingType valueTypes format
        }
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getGroupedRole(id) {
    const query = `{
      tasks(projectId:"${id}", group: ["role", "rid", "roleId", "taskStatus"], order: [["roleId", "ASC"]])
    {
    rid
    role
    roleId
    staffActualTime
    staffTargetTime
    taskStatus
    
      }
    }
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getTasksbyRole(id) {


    const query = `{
      taskAllocations(projectId: "${id}", group: [
        "tid",
        "rid",
        "roleId",
        "staffType",
        "staffName",
         "taskName",
         "taskStatus",
         "stage",
         "stageId",
         "maxTargetTime",
         "minTargetTime",
         "totalTargetTime",
         "dueDate",
         "reportedTime",
         "staffId",
         "feeCalcMode"

      ], order: [["tid", "ASC"]]) {
        tid
        rid
        roleId
        staffType
        staffTargetTime
        staffActualTime
        staffName
        taskName
        taskStatus
        reportedTime
        minTargetTime
        maxTargetTime
        totalTargetTime
        stage
        stageId
        dueDate
        staffId
        feeCalcMode
      }}`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getEmployeeExpenseByProjectId(id) {
    const query = `{
      project(id: "${id}")
      { id
        Expenses{
          amount projectId description
          expense{
            id allocatedFeePercentage expenses paymentId paymentStatus paymentDeliveryDate comments staff { name }
          }
        }
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getFeeDistributionByProjectId(id) {
    const query = `{
    tasks(projectId:"${id}", group: ["staffName"], order: [["staffName", "ASC"]])
    {
    staffName
    staffFeeBudget
      }
    }
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getTaskAllocationByProjectId(id) {
    const query = `{
    tasks(projectId:"${id}", group: ["rid", "role", "staffName"], order: [["rid", "ASC"]])
    {
    rid
    role
    staffName
    staffActualTime
    staffTargetTime
    staffFeeBudget
      }
    }
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getInitialProjectInfo() {
    const query = `{projectRoles {
      id
      rid
      name
    }}`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query,
    })
  }

  getEmpListInfo() {
    const query = `{getProjectInitialData {
    employees {
      id email photoLink name title
    }
  }}`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query,
    })
  }



  // getProjectProgressByProjectId(id) {
  //   const query = `{
  //     projectProgresses(projectId: "${id}")
  //     {
  //       stage
  //       numberofTasks
  //       totalTargetTime
  //       actualTotalTime
  //       startDate
  //       endDate
  //       days
  //     }
  //   }`;

  //   return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
  //     query: query,
  //   });
  // }

  getProjectProgressByProjectId(id) {
    const query = `{
    taskAllocations(projectId:"${id}", group: ["stageId", "stage"], order: [["stageId", "ASC"]])
    {
    stage
    stageId
    taskCount
    staffTargetTime
    staffActualTime
      }
    }
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getRolePerformanceByProjectId(id) {
    const query = `{
    tasks(projectId:"${id}", group: ["rid", "role", "staffName"], order: [["rid", "ASC"]])
    {
    rid
    role
    staffName 
    minTargetTime
    maxTargetTime
    totalTargetTime
    staffActualTime
    staffTargetTime
    autoActualTotalFee
      }
    }
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getStaffPerformanceByProjectId(id) {
    const query = `{
      taskAllocations(projectId:"${id}", group: ["staffName", "staffType"], order: [["staffName", "ASC"]])
      { 
      staffType
      staffName 
      minTargetTime
      maxTargetTime
      totalTargetTime
      staffActualTime
      staffTargetTime
 
      }
    }
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getClientInformationByProjectId(id) {
    const query = `{
      projectClient(projectId: "${id}")
      {
        id clientFileNumber notes useClientName useClientLogo invoiceToClient reportToClient notes customerId projectId
        customer{
          id email title phone cell firstName lastName middleName customerCompanyId
          customerCompany {
            id companyName address city state country zipCode website location clientIdNo taxIdNo dunsNo creditRating
          }
        }
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getProjectRequirementByProjectId(id) {
    const query = `{
      projectRequirement(projectId: "${id}"){
        methodologyApproachesRequired landValueRequired insurableValue frontHalfReportSupport
        dcfSupportSpecialistAssistance specialResearchSupportSpecialistAssistance dataEntrySupportSpecialistAssistance
        systemChoice typeOfInspectionRequired provideDroneFootage provideNonDroneCameraFootage providePropertyDetails
        providePhotos provideMarketNotes costAproach salesComparison incomeApproach incomeApproachDCF
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }
  getProjectFeeByProjectId(id) {
    const query = `{
      projectFee(projectId: "${id}"){
        name type amount comment group index
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  // Group By Stage
  getTaskByGroupedByStage(id) {
    const query =
      `{
    tasks(projectId:"${id}" group: ["stage",  "stageId", "taskStatus"], order: [["stageId", "ASC"]])
    {
      stageId
      stage
      staffActualTime
      staffTargetTime
      taskStatus
    }
  }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }
  getTaskAllocationForFeeAllocation(id) {
    const query = `{
      taskAllocations(projectId: "${id}",
        group:["staffName"], order:["staffName"]) {
          staffName
          staffFeeBudget
          autoActualStaffFee
          autoCustomStaffFee
        }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getTaskByGroupedByAssigne(id) {
    const query =
      `{
    taskAllocations(projectId:"${id}" group: ["staffName", "staffId", "staffType", "role", "taskStatus", "roleId"], order: [["staffName", "ASC"]])
    {
     staffActualTime
     staffTargetTime
     staffName
     staffType
     staffId
     role
     taskStatus
     roleId
   }
 }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }
  getCountryRates() {
    const query = `{
      countryRates{
        role
        rate
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }
  getCompensationIndicies() {
    const query = `{
      compensationIndicies{
        country
        index
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }
  updateProjectTaskAllocations(id, value) {
    const mutation = `mutation updateProjectTaskAllocations($input: projectTaskAllocationInput){
      updateProjectTaskAllocations(
        id: "${id}"
        body: $input
      ){
        id
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }
  updateFeeAllocation(id, value) {
    const mutation = `mutation updateProjectCompanyFeeAllocation($input: [companyFeeAllocationInput]){
      updateProjectCompanyFeeAllocation(
        id: "${id}"
        body: $input
      ){
        splitPercent overheadFee referralFee inspectionFee appraisalFee reviewFee totalFee companyId id
        company{
          companyName
        }
        projectId
        projects {
          projectFee{
            name
            type
            amount
          }
        }
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }
  getCompanies(id, filters: {}) {
    const query = `query customerCompanies($input: [Filter]){
      customerCompanies(id: "${id}" filters: $input){
        id
        companyName
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
      variables: {
        input: filters
      }
    });
  }
  getServicesProvidedByProjectId(id) {
    const query = `{
      taskAllocations(projectId: "${id}",
        group:["staffCompany", "role"], order:["staffCompany"]) {
          staffCompany
          role
        }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getEmployById(StaffId) {
    const query =
      `{
        employee(id: "${StaffId}") {
          name
          email
          title
          photoLink
        }
      }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getEmployeeListForTasks() {
    const query =
      `{
        getEmployeeListForTasks{
          id
          name
          email
          title
          photoLink
          employmentType
          employer {
            companyName
            id
          }
        }
      }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query
    });
  }

  getAllEmployees(filters = {}) {
    const query =
      `query employees($input: [Filter]){
        employees(filters: $input){
          id
          name
          email
          title
          photoLink
        }
      }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
      variables: {
        input: filters
      }
    });
  }

  getServiceProvidedByEmployee(id) {
    const query = `{
      taskAllocations(projectId: "${id}",
        group:["staffName", "role", "rid", "staffId"], order:["staffName"]) {
          staffName
          role
          rid
          autoCustomStaffFee
          staffActualTime
          staffTargetTime
          staffId
        }
    }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  // Mutation for update status by role
  updateStatusByRole(id, body) {
    const mutation = `mutation updateRoleGroupTaskStatus($input: UpdateRoleGroupInput!){
      updateRoleGroupTaskStatus(input: $input){
      parentTaskId
      taskId
      taskStatus
    }
  }
  
  `
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: {
          projectId: id,
          parentTaskId: body.parentTaskId,
          taskStatus: body.taskStatus,
          updatedTotalActualTime: body.updatedTotalActualTime
        }
      }
    })
  }
  updateActualTimeByRole(id, body) {
    const mutation = `mutation updateRoleGroupActualTime($input: UpdateRoleGroupInput!){
      updateRoleGroupActualTime(input: $input){
      parentTaskId
      taskId
      taskStatus
    }
  }
  
  `
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: {
          projectId: id,
          parentTaskId: body.parentTaskId,
          taskStatus: body.taskStatus,
          updatedTotalActualTime: body.updatedTotalActualTime
        }
      }
    })
  }
  // Mutation Update status by stage
  updateStatusByStage(id: any, body: any) {
    const mutation = `mutation updateStageGroup($input: UpdateStageGroupInput!){
      updateStageGroup(input: $input){
       

        taskStatus
        stage{
          id
          name
        }
      }
    }
    
    `
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: {
          projectId: id,
          stageId: body.stageId,
          stage: body.stage,
          taskStatus: body.taskStatus
        }
      }
    })
  }

  updateStatusByStaff(id: any, body: any) {
    const mutation = `mutation updateStaffGroup($input: UpdateStaffGroupInput!){
      updateStaffGroup(input: $input) {
        projectStaff {
          staffId
        }
        projectTask {
          taskStatus
        }
      }
    }
    
    `
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: {
          projectId: id,
          taskStatus: body.taskStatus,
          staffId: body.staffId
        }
      }
    })
  }




  updateIndividualFeeAllocation(id, value) {
    const mutation = `mutation updateProjectFeeAllocation($input: [projectFeeAllocationInput]){
      updateProjectFeeAllocation(
        id: "${id}"
        body: $input
      ){
        totalTotalAmount
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }

  updatePostSplitAllocation(id, value) {
    const mutation = `mutation updatePostSplitAllocation($input: [projectFeeAllocationInput]){
      updatePostSplitAllocation(
        id: "${id}"
        body: $input
      ){
        totalFeeSptRatePercentage
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }

  generateProjectId() {

    return this.http.get<any>(`${this.bashUrl}/generateprojectid`);
  }

  checkProjectId(projectId) {
    return this.http.get<any>(`${this.bashUrl}/checkprojectid?projectId=${projectId}`);
  }

  // Mutation===================================================================

  editClientInformationByProjectId(id, value) {
    const mutation = `mutation updateClientInformation($input: ClientInformationInput) {
      updateClientInformation(
        id: "${id}"
        body: $input
      ) {
        phone
      }}`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  };

  editProjectFeeByProjectId(id, value) {

    const mutation = `mutation updateProjectFee($input: [FeeInput]) {
      updateProjectFee(
        projectId: "${id}",
        body: $input
      ) {
        name
      }
      }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  };

  editEstimateTravelTimeByProjectId(id, value) {

    const mutation = `mutation updateEstimateTravelTime($input: FeeInput) {
      updateEstimateTravelTime(
        projectId: "${id}",
        body: $input
      ) {
        name
      }
      }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  };

  editAssignmentIdByProjectId(id, value) {
    const mutation = `mutation updateAssignmentId($input: AssignmentIdInput) {
      updateAssignmentId(
        id: "${id}",
        body: $input
      ) {
        id
      }
      }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  };

  editComplexityLevelByProjectId(id, value) {
    const mutation = `mutation updateComplexity($input: ComplexityInput) {
      updateComplexity(
        projectId: "${id}",
        body: $input
      ) {
        id appraisalReportType propertyTypeDiffInLevel marketDiffInLevel inspectionDiffInLevel reportTypeDiffInLevel
          clientDiffInLevel totalInLevel propertyTypeDiffInWeight marketDiffInWeight inspectionDiffInWeight reportTypeDiffInWeight
          clientDiffInWeight totalInWeight propertyTypeDiff marketDiff inspectionDiff reportTypeDiff clientDiff
      }
      }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  };

  editSubjectivePropertyByProjectId(id, value) {
    const mutation = `mutation updateProperty($input: ProjectPropertyInput) {
      updateProperty(
        id: "${id}",
        body: $input
      ) {
        address
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }
  // edit Additional Requirements By ProjectId

  editAdditionalRequirementsByProjectId(id, value) {
    const mutation = `mutation updateRequirements($input: RequirementInput) {
      updateRequirements(
        projectId: "${id}",
        body: $input
      ) {
        dataEntrySupportSpecialistAssistance
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }

  getProjectDistribution(id) {
    const query = `{
      projectDistributions(projectId: "${id}") {
        id paidAmount paymentAmount paymentId paymentStatus paymentDeliveryDate comments staffId projectStaff {
          id
          name
          employmentType
          email
        }
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  updateProjectDistribution(id, value) {
    const mutation = `mutation updateProjectDistribution($input: [ProjectDistributionInput]) {
      updateProjectDistribution(
        id: "${id}",
        body: $input
      ) {
        message
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }




  // Taskallocations by role

  getTaskDatabyRole(id, groupId) {


    const query = `{
    taskAllocationsByRole(projectId: "${id}", rid: "${groupId}", group:["tid",
          "rid",
          "roleId",
          "staffType",
          "staffName",
           "taskName",
           "taskStatus",
           "stage",
           "stageId",
           "maxTargetTime",
           "minTargetTime",
           "totalTargetTime",
           "dueDate",
           "reportedTime",
           "staffId",
           "feeCalcMode",
          ,"taskId"], order: [["tid", "ASC"]])  {

          
          taskId
          tid
          rid
          roleId
          staffType
          staffTargetTime
          staffActualTime
          staffName
          taskName
          taskStatus
          reportedTime
          minTargetTime
          maxTargetTime
          totalTargetTime
          stage
          stageId
          dueDate
          staffId
          feeCalcMode
    }
  }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }


  getTaskDataByStage(id, groupId) {


    const query = `{
    taskAllocationsByStage(projectId: "${id}", stageId: "${groupId}", group:["tid",
          "rid",
          "roleId",
          "staffType",
          "staffName",
           "taskName",
           "taskStatus",
           "stage",
           "stageId",
           "maxTargetTime",
           "minTargetTime",
           "totalTargetTime",
           "dueDate",
           "reportedTime",
           "staffId",
           "feeCalcMode"
          ,"taskId"], order: [["tid", "ASC"]])  {
            taskId
       tid
          rid
          roleId
          staffType
          staffTargetTime
          staffActualTime
          staffName
          taskName
          taskStatus
          reportedTime
          minTargetTime
          maxTargetTime
          totalTargetTime
          stage
          stageId
          dueDate
          staffId
          feeCalcMode
    }
  }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }


  getTaskDataByStaff(id, groupId) {
    const query = `{
    taskAllocationsByStaff(projectId: "${id}", staffId: "${groupId}", group:["tid",
          "rid",
          "roleId",
          "staffType",
          "staffName",
           "taskName",
           "taskStatus",
           "stage",
           "stageId",
           "maxTargetTime",
           "minTargetTime",
           "totalTargetTime",
           "dueDate",
           "reportedTime",
           "staffId",
           "feeCalcMode",
          "taskId"], order: [["tid", "ASC"]])  {
            taskId
       tid
          rid
          roleId
          staffType
          staffTargetTime
          staffActualTime
          staffName
          taskName
          taskStatus
          reportedTime
          minTargetTime
          maxTargetTime
          totalTargetTime
          stage
          stageId
          dueDate
          staffId
          feeCalcMode
    }
  }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getProjectTasks(taskId) {
    const query = `{
      projectTask(id: "${taskId}") {
        id
        reportedTime
        feeCalcMode
        name
        minTargetTime
        maxTargetTime
        totalTargetTime
        dueDate
        parentTaskId
        taskId
        taskStatus
        projectStageId
        projectRoles {
          id
          name
          rid
        }
        stage {
          id
          name
        }
    taskAllocations {
      id
      targetTime
      actualTime
      feeBudget
      autoActualFee
      customActualFee
      personalGoal
      projectStaff {
        id
        type
        staff {
          id
          name
          email
          title
          photoLink
        }
      }
    }
  }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getProjectTimeScheduleByProjectId(projectId) {
    const query = `{
      projectTimeSchedule(projectId: "${projectId}") {
        stages {
          name
        }
        lagInDays
        actualStartDate
        actualEndDate
        targetStartDate
        targetEndDate
        baseStartDate
        baseEndDate
        actualDurationInDays
        targetDurationInDays
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }


  editTaskListBytaskId(body: any) {
    const mutation = `mutation editTask($input: TaskInput!){
      editTask(
        input: $input
      ){
        id
        reportedTime
        feeCalcMode
        name
        minTargetTime
        maxTargetTime
        dueDate
        parentTaskId
        taskId
        taskStatus
        projectStageId
        projects {
          id
        }
        projectRoles {
          id
          name
        }
        stage {
          id
          name
        }
        taskAllocations {
          id
          targetTime
          actualTime
          personalGoal
          projectStaff {
            id
            type
            staffId
          }
        }
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: body
      }
    });
  }

  getAllRoles() {
    const query =
      `{
      projectRoles(order: "id") {
        id
        name
        rid
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getAllStages() {
    const query =
      `{
        projectStages {
          id
          name
        }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  addTaskByProjectId(id: any, body: any) {
    const mutation = `mutation addTask($input: TaskInput!){
      addTask(
        input: $input
      ){
        id
        reportedTime
        feeCalcMode
        name
        minTargetTime
        maxTargetTime
        dueDate
        parentTaskId
        taskId
        taskStatus
        projectStageId
        projects {
          id
        }
        projectRoles {
          id
          name
        }
        stage {
          id
          name
        }
        taskAllocations {
          id
          targetTime
          actualTime
          personalGoal
          projectStaff {
            id
            type
            staffId
          }
        }
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: body
      }
    });
  }

  updateTaskStatus(body) {
    const mutation = `
      mutation Mutation($input: TaskStatusUpdateInput!) {
    updateTaskStatus(input: $input) {
      id
      taskStatus
      taskAllocations {
        targetTime
        actualTime
        id
      }
    }
  }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: body
      }
    });
  }

  getTaskIdByRoleId(projectId, projectRoleId) {
    const query = `{
      getTaskIdByRole(projectId: "${projectId}", projectRoleId: ${projectRoleId}) {
        taskId
      }
    }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getProjectFeeAllocationByProjectId(projectId) {
    const query = `{
      projectFeeAllocations(projectId: "${projectId}") {
        id
        allocationApprovedByEmployee
        totalTotalAmount
        notes
        involvementType
        group
        totalAutoCalFeePercentage
        totalAutoCalFeeAmount
        staff{
          id
          name
          company
          email
          compensationMethod
          employmentType
        }
        totalTotalRatePercentage
        totalFeeSptRatePercentage
        totalFeeAmount
        totalOverHeadRatePercentage  
        totalOverheadAmount
        totalProfitRatePercentage
        totalProfitAmount
        totalTotalRatePercentage
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  allocatedStaffForProjectFees(id) {
    const query = `{
      allocatedStaffForProjectFees(projectId: "${id}"){
        id
        staff{
           id
           name
           company
           email
           compensationMethod
           employmentType
         }
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  updateDefaultRole(body: any) {

    const mutation = `
      mutation UpdateDefaultRole($input: UpdateDefaultRoleInput!) {
        updateDefaultRole(input: $input) {
      id
      staff {
        id
        name
        email
        title
        photoLink
      }
      removeAssignee
    }
  }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: body
      }
    });
  }

  // mutation UpdateCustomerInformation($input: ClientInput) {
  //   updateCustomerInformation(input: $input) {

  updateProjectContractInformation(projectId, value) {
    const mutation = `
    mutation updateProjectContractInformation($input: [ProjectContractInformationInput]) {
      updateProjectContractInformation(
        projectId: "${projectId}" body: $input) {
          id key value comment
      }
    }
    `;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: value
      }
    });
  }
  // DeleteSection
  deleteTask(taskId) {
    const mutation = `
    mutation DeleteTask($input: ID!) {
      deleteTask(input: $input)
    }
    `;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: taskId
      }
    });
  }

  changeFeeAllocApproach(projectId, value) {
    const mutation = `
      mutation updateFeeAllocApproach($input: FeeAllocInput) {
        updateFeeAllocApproach(projectId: "${projectId}"
          body: $input) {
          allocApproach
        }
      }
    `;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: { allocApproach: value }
      }
    });
  }


  /*
  ==================================
   ProjectAccountingAssetsProperty 
   for service and specification under finance tab
  */
  getAccountingAssetsPropertyByProjectId(projectId) {
    const query = `{
      projectAccountingAssetPropertys(projectId: "${projectId}") {
        id
        key
        value
        comment
        projectId
      }
    }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  updatePropertyAssets(projectId: any, propertyAssetData: any) {
    const mutation = `mutation UpdateProjectAccountingAssetPropertys( $input: [ProjectAccountingAssetPropertyInput]) {
      updateProjectAccountingAssetPropertys(projectId: "${projectId}", input: $input) {
        id
        key
        comment
        value
        projectId
      }
    }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: propertyAssetData }
    });
  }
  //================================

  updateClientBilling(projectId, body) {
    const mutation = `mutation updateClientBilling($input: [ClientBillingInput]) {
      updateClientBilling(projectId: "${projectId}", body: $input) {
        milestones
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: body }
    });
  }


  updateProjectInfo(projectId, body) {
    const mutation = `
     mutation UpdateProject($input: ProjectInfoInput) {
      updateProject(id: "${projectId}", body: $input) {
        name
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: body }
    });
  }

  archiveProject(projectId) {
    const mutation = `mutation ArchiveProject($input: ID!) {
      archiveProject(projectId: $input) {
        isDeleted
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: `${projectId}` }
    });
  }

  // groupTaskAllocationsByStaffName(projectId) {
  //   const query = `{
  //     taskAllocations(projectId:"${projectId}", group: ["staffName"])
  //     { 
  //       staffName
  //       staffTargetTime
  //     }
  //   }
  //   `;
  //   return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
  //     query: query,
  //   });
  // }

  groupTaskAllocationsByTaskStatus(projectId) {
    const query = `{
      taskAllocations(projectId:"${projectId}", group: ["taskStatus"], order: [["taskStatus", "ASC"]])
      { 
        taskStatus
        staffTargetTime
      }
    }
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getProjectCompanyDistributions(projectId) {
    const query = `{
      getProjectCompanyDistributionsByProjectId(projectId:"${projectId}") {
        id
        paymentAmount
        paymentId
        paymentStatus
        paymentDeliveryDate
        comments
        companyFeeAllocation {
          company {
            companyName
          }
          splitPercent
          concludedAmountOfNetFee
        }
        projectCompanyFeeAllocationId
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  updateProjectCompanyDistribution(id, value) {
    const mutation = `mutation updateProjectCompanyDistributions($input: [ProjectCompanyDistributionInput]) {
      updateProjectCompanyDistributions(
        id: "${id}",
        body: $input
      ) {
        message
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: value }
    });
  }

  getSummaryDataForProject(id) {
    const query = `{
      getSummaryData(projectId: "${id}") {
        projectProgress {
          stage
          taskCount
          staffTargetTime
          staffActualTime
          actualStartDate
          actualEndDate
          actualDurationInDays
          targetStartDate
          targetEndDate
          targetDurationInDays
        }
        roleProgress {
          rid 
          role
          staffName
          totalTargetTime
          actualTimeTrainee
          staffWorkCompleted
          traineeWorkCompleted
          staffActualTime
          traineeActualTime
          overrunTrackingTime
          overrunTrackingPercent
        }
        staffPerformance {
          staffType
          staffName
          remainingHours
          maxTargetTime
          minTargetTime
          totalTargetTime
          staffTargetTime
          staffActualTime
          overrunTrackingTime
          overrunTrackingPercent
          workCompleted
        }
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }
  getCountryList() {
    const query = `{
      getCountryList {
        isoCode
        name
        phonecode
    }}`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  deleteAllTasksByGroup(input) {
    const mutation = `mutation deleteTaskByGroup($input: DeleteAllTaskInput) {
      deleteTaskByGroup(
        input: $input
      )
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: input }
    });
  }

  getClientAccountingData() {
    const query = `{
      getClientAccountingData {
        clientCompany
        clientInvoiced
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getPartnerAccountingData() {
    const query = `{
      getPartnerAccountingData {
        companyName
        companyId
        creditAmount
        debitAmount
        balance
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getContractorAccountingData() {
    const query = `{
      getContractorAccountingData {
        staffId
        contractorName
        debitAmount
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  
  getPartnerCompaniesAndTheirDefaultEmployees() {
    const query = `{
      getPartnerCompaniesAndTheirDefaultEmployees {
        employer {
          companyName
        }
        name
        companyId
      }
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query
    })
  }

  updateAllocationStatus(allocationId, approvalStatus) {
    const mutation = `mutation updateAllocationApprovalStatus($input: projectFeeAllocationInput) {
      updateAllocationApprovalStatus(
        data: $input
      )
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input: { id: allocationId, allocationApprovedByEmployee: approvalStatus } }
    });
  }
}
