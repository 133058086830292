import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { GraphqlService } from './graphql.service';

@Injectable()
export class CommonService {
    bashUrl = '';
    bashEmployeeUrl = '';
    constructor(private http: HttpClient, private router: Router, private graphqlService: GraphqlService) { }
    getCountryList() {
        const query = `{
          getCountryList {
            isoCode
            name
            phonecode
        }}`;

        return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
            query: query,
        });
    }
    getStateListByCountry(countryCode) {
        const query = `{
            getStateListByCountry(countryCode: "${countryCode}") {
            isoCode
            countryCode
            name
            
        }}`;

        return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
            query: query,
        });
    }
    getCityListByState(countryCode, stateCode) {
        const query = `{
            getCityListByState(countryCode:"${countryCode}", stateCode:"${stateCode}") {
            stateCode
            countryCode
            name
            
        }}`;

        return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
            query: query,
        })
    }

    getEmployeeByMailId(email) {
        const query = `{
          
            employeeByEmail(email: "${email}") {
              id
              name
              email
              photoLink
              title
            }
         
        }`
        return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
            query: query,
        });

    }


    minuteConversion(minutes) {
        let hrs = minutes / 60;
        let fractionalHrs = hrs % 1;
        let convertedMins = fractionalHrs * 60;
        if (Math.round(convertedMins) >= 60) {
            if (Math.round(convertedMins) > 60) convertedMins -= 60;
            else convertedMins = 0;
            hrs++;
        }
        if (Math.round(convertedMins) < 10) return parseFloat(Math.floor(hrs) + '.' + '0' + Math.round(convertedMins));
        return parseFloat(Math.floor(hrs) + '.' + Math.round(convertedMins));
    }

    hoursConversion(hours) {
        let fractionalHrs = hours % 1;
        let convertedMins = fractionalHrs * 60;

        if (Math.round(convertedMins) >= 60) {
            if (Math.round(convertedMins) > 60) convertedMins -= 60;
            else convertedMins = 0;
            hours++;
        }

        if (Math.round(convertedMins) < 10) return parseFloat(Math.floor(hours) + '.' + '0' + Math.round(convertedMins));

        return parseFloat(Math.floor(hours) + '.' + Math.round(convertedMins));
    }

    convertDecimalTimeToSeparatedTime(time) {
        return time.toFixed(2).toString().replace('.', ':');
    }
}
