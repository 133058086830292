import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { EventService } from '../../../services/event.service';
import { KeycloakService } from '../../../services/auth/keycloak.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { environment } from 'src/environments/environment';
import { HostService } from 'src/app/services/host.service';



@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userData: any;
  isAdmin: any;
  isManager: any;
  currentRoute: string;
  @ViewChild('mobileMenuWrapper', { static: true }) MenuWrapper: ElementRef;
  // metabaseURL: String;
  selectedTenant: any;
  userTenants: any[];

  constructor(
    private authService: AuthService,
    private broadcaster: EventService,
    private keycloakService: KeycloakService,
    private router: Router,
    private hostService: HostService
  ) {
    // if(environment.production) {
    //   this.metabaseURL = 'https://archstone-mis.canyondata.tech/';
    // } else this.metabaseURL = 'https://stg.canyondata.tech/mis/';
  }

  ngOnInit(): void {
    this.userData = this.hostService.getUserInfo();
    this.userTenants = this.hostService.getTenants();
    this.selectedTenant = this.userTenants.find(ut => ut.tenancyName === this.hostService.getCurrentTenant());
    this.currentRoute = "";
    this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
            this.MenuWrapper.nativeElement.classList.remove('show');
        }
    });
  }

  getKeycloakService() {
    return KeycloakService;
  }

  setTenant(tenantName){
    this.hostService.setCurrentTenant(tenantName);
    window.location.href = "/";
  }

  logout() {
    this.authService.logout();
  }
}
