import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable()
export class HostService {
  apiUrl = '';

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) {
    this.apiUrl = `${environment.baseUrl}/api/host`;
  }

  inviteUser(user) {
    return this.http.post<any>(`${this.apiUrl}/user`, user);
  }

  getUserInfo() {
    return JSON.parse(this.cookieService.get('user-info'));
  }

  getTenants() {
    return JSON.parse(this.cookieService.get('user-tenants'));
  }

  getCurrentTenant() {
    return this.cookieService.get('user-current-tenant');
  }

  setCurrentTenant(tenantName: string) {
    const domain = new URL(window.location.href)
    this.cookieService.set('user-current-tenant', tenantName, null, null, domain.hostname);
  }
}
