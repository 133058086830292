import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

// ===================================================
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// ===================================================

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnChanges {

  @Input() optionsArray: any[] = [];
  @Input() options: any[] = [];
  @Output() selectedOptions = new EventEmitter<Object>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  optionCtrl = new FormControl();
  filteredOptions: Observable<string[]>;

  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;

  constructor() {

    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => (option ? this._filter(option) : this.optionsArray.slice())),
    );

  }

  ngOnChanges(): void {

  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our option
    if (value) {
      this.options.push(value);
      this.selectedOptions.emit(this.options);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.optionCtrl.setValue(null);
  }

  remove(option: string): void {
    const index = this.options.indexOf(option);

    if (index >= 0) {
      this.options.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    this.optionInput.nativeElement.value = '';
    this.optionCtrl.setValue(null);
    this.selectedOptions.emit(this.options);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionsArray.filter(option => option.toLowerCase().includes(filterValue));
  }
}