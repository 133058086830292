<div class="user-profiler row" *ngIf="company">
  <div class="profiler-content w-auto p-0">
    <h5>
      <b>{{ company?.companyName }}</b>
    </h5>
    <p class="sml-txt">
      {{ company.address }}
      {{ company.city ? (company.city !== "null" ? ', ' + company.city : '') : '' }}
      {{ company.state ? (company.state !== "null" ? ', ' + company.state : '') : '' }}
      {{ company.country ? (company.country !== "null" ? ', ' + company.country : '') : '' }}
      {{ company.zipCode ? ', ' + company.zipCode : ''}}
    </p>

    <div class="list-view-btn">
      <span (click)="showCompanyListWithEvent($event)" class="list-show-btn"><i class="fas fa-chevron-down"></i></span>
    </div>
  </div>
  <div class="list-view-cnt" style="top: 35px" [ngClass]="showlist ? 'show' : ''">
    <div class="list-input">
      <input
        #tgArea
        type="text"
        autocomplete="off"
        placeholder="Start typing the name.."
        class="w-100"
        [(ngModel)]="search"
        #input="ngModel"
        id="search"
        name="search"
      />
      <section class="ta-results list-group" *ngFor="let company of filteredList">
        <button type="button" class="ta-item list-group-item" (click)="handleSelectedCompany(company)">
          <div class="item-name">
            <div class="profiler-content">
              <h5>
                <b>{{ company?.companyName }}</b>
              </h5>
              <p class="sml-txt">
                {{ company.address }}
                {{ company.city ? (company.city !== "null" ? ', ' + company.city : '') : '' }}
                {{ company.state ? (company.state !== "null" ? ', ' + company.state : '') : '' }}
                {{ company.country ? (company.country !== "null" ? ', ' + company.country : '') : '' }}
                {{ company.zipCode ? ', ' + company.zipCode : ''}}
              </p>
            </div>
          </div>
        </button>
      </section>
    </div>
  </div>
</div>
